//根路径的处理
const mixins = {
    data() {
        return {
            lang: '/us-en',
            bgTest: require('@/assets/images/solution/top_banner.png'),
            footbgs: {
                bg1: require('@/assets/images/footerbg/bg1.jpg'),
                bg2: require('@/assets/images/footerbg/bg2.jpg'),
                bg3: require('@/assets/images/footerbg/bg3.jpg'),
                bg4: require('@/assets/images/footerbg/bg4.jpg'),
                bg5: require('@/assets/images/footerbg/bg5.png'),
            },
        };
    },
    mounted() {
        // this.lang = '/zh-cn'
        this.lang = this.lang
        localStorage.setItem('lang', this.lang)
    },
    methods: {
        goUrl(url) {
            this.$router.push(this.lang + url)
        },
        goWeb(url) {
            let a = document.createElement('a')
            a.target = "_blank"
            a.href = url
            a.click()
        },
        goBuilding() {
            this.$router.push(this.lang + '/PageThatIsInDevelopment')
        },
        setBg(str) {
            let obj = {
                backgroundImage: 'url(' + str + ')',
                backgroundPosition: 'top',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }
            return obj
        },
    },
}

export default mixins
/** 
 * cors
 * proxy:开发环境
 */
